<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户id" prop="userId">
                <a-input v-model="queryParam.userId" placeholder="请输入用户id" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="签到配置id" prop="signRuleId">
                <a-input v-model="queryParam.signRuleId" placeholder="请输入签到配置id" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="签到时间(格式 yyyy-MM-dd)" prop="signTime">
                  <a-input v-model="queryParam.signTime" placeholder="请输入签到时间(格式 yyyy-MM-dd)" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="获得金币数" prop="gold">
                  <a-input v-model="queryParam.gold" placeholder="请输入获得金币数" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="其他工具id，道具、礼物、特权卡" prop="toolsId">
                  <a-input v-model="queryParam.toolsId" placeholder="请输入其他工具id，道具、礼物、特权卡" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="签到设备名称" prop="clientId">
                  <a-input v-model="queryParam.clientId" placeholder="请输入签到设备名称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="签到纬度" prop="lat">
                  <a-input v-model="queryParam.lat" placeholder="请输入签到纬度" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="签到经度" prop="lng">
                  <a-input v-model="queryParam.lng" placeholder="请输入签到经度" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="签到地址" prop="city">
                  <a-input v-model="queryParam.city" placeholder="请输入签到地址" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否是会员 0:非会员 1:会员" prop="vipFlag">
                  <a-input v-model="queryParam.vipFlag" placeholder="请输入是否是会员 0:非会员 1:会员" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="创建人" prop="createBy">
                  <a-input v-model="queryParam.createBy" placeholder="请输入创建人" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="修改人" prop="updateBy">
                  <a-input v-model="queryParam.updateBy" placeholder="请输入修改人" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:signIn:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:signIn:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:signIn:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['biz:signIn:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['biz:signIn:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:signIn:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['biz:signIn:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['biz:signIn:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageSignIn,listSignIn, delSignIn } from '@/api/biz/signIn'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'SignIn',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        signRuleId: null,
        signTime: null,
        signType: null,
        gold: null,
        toolsId: null,
        clientId: null,
        lat: null,
        lng: null,
        city: null,
        vipFlag: null,
        createBy: null,
        updateBy: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户id',
          dataIndex: 'userId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '签到配置id',
          dataIndex: 'signRuleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '签到时间(格式 yyyy-MM-dd)',
          dataIndex: 'signTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '类型：1:金币 2:道具 3:礼物 4:特权卡',
          dataIndex: 'signType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '获得金币数',
          dataIndex: 'gold',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '其他工具id，道具、礼物、特权卡',
          dataIndex: 'toolsId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '签到设备名称',
          dataIndex: 'clientId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '签到纬度',
          dataIndex: 'lat',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '签到经度',
          dataIndex: 'lng',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '签到地址',
          dataIndex: 'city',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否是会员 0:非会员 1:会员',
          dataIndex: 'vipFlag',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'createBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '修改人',
          dataIndex: 'updateBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询签到-记录列表 */
    getList () {
      this.loading = true
     pageSignIn(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        signRuleId: undefined,
        signTime: undefined,
        signType: undefined,
        gold: undefined,
        toolsId: undefined,
        clientId: undefined,
        lat: undefined,
        lng: undefined,
        city: undefined,
        vipFlag: undefined,
        createBy: undefined,
        updateBy: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delSignIn(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/signInRecord/export', {
            ...that.queryParam
          }, `签到-记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
