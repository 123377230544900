<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="签到配置id" prop="signRuleId" >
        <a-input v-model="form.signRuleId" placeholder="请输入签到配置id" />
      </a-form-model-item>
      <a-form-model-item label="签到时间(格式 yyyy-MM-dd)" prop="signTime" >
        <a-input v-model="form.signTime" placeholder="请输入签到时间(格式 yyyy-MM-dd)" />
      </a-form-model-item>
      <a-form-model-item label="类型：1:金币 2:道具 3:礼物 4:特权卡" prop="signType" >
      </a-form-model-item>
      <a-form-model-item label="获得金币数" prop="gold" >
        <a-input v-model="form.gold" placeholder="请输入获得金币数" />
      </a-form-model-item>
      <a-form-model-item label="其他工具id，道具、礼物、特权卡" prop="toolsId" >
        <a-input v-model="form.toolsId" placeholder="请输入其他工具id，道具、礼物、特权卡" />
      </a-form-model-item>
      <a-form-model-item label="签到设备名称" prop="clientId" >
        <a-input v-model="form.clientId" placeholder="请输入签到设备名称" />
      </a-form-model-item>
      <a-form-model-item label="签到纬度" prop="lat" >
        <a-input v-model="form.lat" placeholder="请输入签到纬度" />
      </a-form-model-item>
      <a-form-model-item label="签到经度" prop="lng" >
        <a-input v-model="form.lng" placeholder="请输入签到经度" />
      </a-form-model-item>
      <a-form-model-item label="签到地址" prop="city" >
        <a-input v-model="form.city" placeholder="请输入签到地址" />
      </a-form-model-item>
      <a-form-model-item label="是否是会员 0:非会员 1:会员" prop="vipFlag" >
        <a-input v-model="form.vipFlag" placeholder="请输入是否是会员 0:非会员 1:会员" />
      </a-form-model-item>
      <a-form-model-item label="创建人" prop="createBy" >
        <a-input v-model="form.createBy" placeholder="请输入创建人" />
      </a-form-model-item>
      <a-form-model-item label="修改人" prop="updateBy" >
        <a-input v-model="form.updateBy" placeholder="请输入修改人" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSignIn, addSignIn, updateSignIn } from '@/api/biz/signIn'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        signRuleId: null,

        signTime: null,

        signType: null,

        gold: null,

        toolsId: null,

        clientId: null,

        lat: null,

        lng: null,

        city: null,

        vipFlag: null,

        createBy: null,

        createTime: null,

        updateBy: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id不能为空', trigger: 'blur' }
        ],
        signRuleId: [
          { required: true, message: '签到配置id不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        signRuleId: null,
        signTime: null,
        signType: null,
        gold: null,
        toolsId: null,
        clientId: null,
        lat: null,
        lng: null,
        city: null,
        vipFlag: null,
        createBy: null,
        createTime: null,
        updateBy: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSignIn({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSignIn(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSignIn(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
